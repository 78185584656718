<template>
  <div>
    <AdminTitle title="Projects">
      <template v-slot:center>
        <v-sheet max-width="600" width="100%">
          <v-text-field
            v-model="keyword"
            @keydown.enter="search()"
            @click:clear="$store.commit('allProjects/clearHits')"
            placeholder="Search project"
            class="field-shadow"
            hide-details
            clearable
            rounded
            outlined
          >
            <template v-slot:append>
              <v-btn
                @click="search()"
                :loading="status.searching"
                class="mt-n2"
                icon
              >
                <v-icon>mdi-folder-search</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-sheet>
      </template>
      <template v-slot:actions>
        <div class="d-flex gap-12 align-center">
          <v-btn
            @click="getProjects()"
            :loading="status.getting"
            icon
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </div>
      </template>
    </AdminTitle>


    <!-- FILTERS -->
    <div>
      <div class="d-flex justify-space-between align-center mb-10">
        <div v-if="status.displayRange">
          Displaying <span class="font-weight-bold">{{ orderedProjects.length }} Project{{ orderedProjects.length > 1 ? 's' : '' }}</span> in the last 60 Days
        </div>
        <div v-else>
          Displaying All <span class="font-weight-bold">{{ orderedProjects.length }} Project{{ orderedProjects.length > 1 ? 's' : '' }}</span>
        </div>

        <div class="d-flex gap-12 align-center">

          <v-sheet max-width="250">
            <v-select
              @change="getProjects()"
              v-model="status.displayRange"
              :items="displayRanges"
              item-text="name"
              item-value="value"
              prefix="Display: "
              hide-details
              class="dashboard-select field-shadow"
              outlined
              dense
            >
            </v-select>
          </v-sheet>

          <v-sheet max-width="250">
            <v-select
              v-model="order"
              :items="orderBy"
              item-text="name"
              item-value="value"
              prefix="Sort by: "
              hide-details
              class="dashboard-select field-shadow"
              outlined
              dense
            ></v-select>
          </v-sheet>

          <v-btn-toggle
          mandatory
          >
            <v-btn small icon
            @click="changeView('grid')"
            >
              <v-icon small>mdi-view-grid</v-icon>
            </v-btn>
            <v-btn small icon
            @click="changeView('list')"
            >
              <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </div>

    <!-- CONTENT -->
      <div>
        <preloader v-if="(status.getting || status.searching) && listView == 'grid'" />
        
        <v-alert type="info" border="left" v-if="!status.getting && !projects.length" text>No project found</v-alert>
        
        <v-fade-transition 
          v-if="!status.getting && !status.searching && projects.length && listView == 'grid'"
          name="projects"
          class="row"
          tag="div"
          group
        >
          <v-col 
            v-for="project in orderedProjects" 
            :key="project.id"
            cols="3" 
          >
            <project-block
              :project="project"
              disableMoveProjectFolder
              @update="$parent.showAdd()"
            />
          </v-col>
        </v-fade-transition>

        <preloader v-if="(status.getting || status.searching) && listView == 'list'" />
        
        <div v-if="!status.getting && !status.searching">
          <v-alert v-if="!orderedProjects.length && listView == 'list'" type="info" border="left" class="mb-0" text>No Project found.</v-alert>
          
          <v-row v-if="!status.getting && projects.length && listView == 'list'">
            <!-- LIST FOLDERS -->
            <v-col>
              <v-card class="flex-grow-1" outlined>
                <template>
                  <v-alert v-if="!projects.length" type="info" border="left" class="mb-0">No users found.</v-alert>
                  <v-simple-table v-if="orderedProjects.length && listView == 'list'">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th width="15"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="project in orderedProjects"
                          :key="project.id"
                          outlined
                          hover
                        >
                          <td>
                            <div class="d-flex align-center">
                              <span class="font-weight-bold">
                                <router-link :to="{ name: 'Project', params: { id: project.id } }" class="text-decoration-none">
                                  <div 
                                    v-if="project.collaborator"
                                  >
                                    <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                      v-on="on"
                                      color="primary"
                                      class="mr-2"
                                      >
                                        mdi-account-group-outline
                                      </v-icon>
                                      <span class="black--text">
                                        {{ project.name }}
                                      </span>
                                    </template>
                                      <span class="caption"><span class="text-capitalize">Project Collaborator</span></span>
                                      </v-tooltip>
                                  </div>
                                  <div v-if="!project.collaborator">
                                    {{ project.name }} <div class="d-inline font-weight-medium caption ml-2">created {{ project.createdAt | fromNow }} ago  by <owner :id="project.owner" /></div>
                                  </div>
                                </router-link>
                              </span>
                            </div>
                          </td>
                          <td>
                            <v-menu bottom left offset-y transition="slide-x-transition" nudge-bottom="12">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  color="primary"
                                  class="subtle"
                                  icon
                                  v-on="on"
                                  small
                                >
                                  <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item @click="
                                  $store.commit('projects/setData', project)
                                  $parent.showAdd()
                                " 
                                dense 
                                class="subtle"
                                >
                                  <v-list-item-title class="primary--text"><v-icon small left>mdi-form-textbox</v-icon> Rename</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                </template>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>

  <!-- ADD EDIT DIALOG -->
  <AddEditDialog />
  <!-- Collaborators Dialog -->
  <CollaboratorDialog />
  </div>
</template>


<script>
import ProjectBlock from '@/components/ProjectBlock'
import AddEditDialog from '@/components/AddEditUser'
import CollaboratorDialog from '@/components/CollaboratorDialog'
import { mapState, mapActions } from 'vuex'
import _orderBy from 'lodash/orderBy'

export default {
  name: 'AllProjects',

  metaInfo: {
    title: 'All Projects'
  },

  /* -------------------------------------------------------------------------- */
  /*                                 COMPONENTS                                 */
  /* -------------------------------------------------------------------------- */
  components: {
    ProjectBlock,
    AddEditDialog,
    CollaboratorDialog,
  },

  /*------------------------------------------------------------------------------
   * DATA
   ----------------------------------------------------------------------------*/
   data() {
     return {
       confirm: false,
       deleteMessage: null,
       toDelete: null,
       order: 'createdAt',
       listView: 'grid',
       keyword: null,
       displayRanges: [
        {
          name: 'Last 60 Days',
          value: 60
        },
        {
          name: 'Last 30 Days',
          value: 30
        },
        {
          name: 'Last 7 Days',
          value: 7
        },
        {
          name: 'All',
          value: 0
        },
      ],

      orderBy: [
        {
          name: 'Date Created',
          value: 'createdAt'
        },
        {
          name: 'Name',
          value: 'name'
        }
      ],
     }
   },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      hits: state => state.allProjects.hits,
      status: state => state.allProjects.status,
      projects: state => state.allProjects.projects,
      collaborator: state => state.allProjects.collaborator,
    }),

    orderedProjects: function () {
      let orderTypeProjects = this.order == 'createdAt' ? 'desc' : 'asc'
      let projects = this.hits.length ? this.hits : this.projects

      return _orderBy(projects, this.order , orderTypeProjects)
      },
    },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('allProjects', [
      'getProjects',
      'searchProject'
    ]),

    changeView( active ) {
      this.listView = active
    },

    search() {
      this.searchProject(this.keyword)
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    if (!this.$store.state.allProjects.status.firstLoad){
      this.getProjects()
    }

  }

}
</script>
